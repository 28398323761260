














// @ts-nocheck
import {Component, Watch, Vue} from "vue-property-decorator";
import RawlplugFilesManager from "@/resources/myLib.common.js";
import bytesToSize from "@/resources/bytesToSize";
import MediaCenterAPI from "@/services/MediaCenterAPI";

function parseResponse(responseStatusCodeManager, data, callBack, callBackError) {
  responseStatusCodeManager.register(201, (data, statusCode) => {
    callBack();
  }).catch((exception) => {
    callBackError(exception);
  });
  responseStatusCodeManager.call(data, data.status);
}

var FileUploader = function(options) {
  var settings = {
    done: function(data) {
    },
    error: function(data) {
    },
    progress: function(data) {
    },
    url: "",
  };
  settings = $.extend({}, options);

  return {
    sendFile: async function(file) {
      var formData = new FormData();
      formData.append("attach[]", file);
      // formData.append("folder", path);
      window.formData = formData;

      let options = {
        // mode: 'cors',
        method: "POST",
        body: formData,
        headers: new Headers(
          {
            //  "Content-Type": "application/json",
            "Accept": "application/json",
          }
        )
      };
      const responseStatusCodeManager = new ResponseStatusCodeManager();
      const data = await fetch(settings.url,
        options
      ).then(async (data) => {
        parseResponse(responseStatusCodeManager, data, () => {
            settings.done(data);
          }, (exception) => {
            settings.error(exception);
          },
        );
      }).catch((e) => {
        responseStatusCodeManager.sendError(e);
      });
    }
  };
};

const FunctionActions = function(app: any) {
  return {
    get: function() {
      return [
        (this.previewButton()),
        (this.downloadButton()),
        (this.deleteButton()),
      ];
    },

    deleteButton: function() {
      return {
        icon: "fa fa-delete",
        label: "delete",
        text: "Delete",
        onlyOwner: true,
        attr: {
          href: "",
          target: ""
        },
        onClick: async (e: any, file: any, index: number) => {
          const hash = file.hash;
          const fileId = app.findFile(index);
          app.pushToActionQuote({
            file,
            hash,
            action: "delete",
          });
        },
      };
    },
    previewButton: function() {
      return {
        icon: "fa fa-eye",
        label: "preview",
        text: "",
        attr: {
          href: "",
          target: ""
        },
        onClick: function(e: any, file: any, index: number, z: any) {
          const hash = file.hash;
          const fileId = app.findFile(index);
          const packageHashName = FunctionListeners.packageHashName;
          const url = `http://mcv.rawl-app.lkoc.webdev/${packageHashName}/${file.hash}`;
          // @ts-ignore
          window.open(url, "_blank").focus();
          return false;
        },
      };
    },
    downloadButton: function() {
      return {
        icon: "fa fa-download",
        label: "download",
        text: "Download",
        attr: {
          href: "",
          target: ""
        },
        onClick: function(e: any, file: any, index: number, z: any) {
          const hash = file.hash;
          const fileId = app.findFile(index);
          const packageHashName = FunctionListeners.packageHashName;
          const url = `http://mcv.rawl-app.lkoc.webdev/d/${packageHashName}/${file.hash}`;
          // @ts-ignore
          window.open(url, "_blank").focus();
          return false;
        },
      };
    },
  };
};

const FunctionFileUploaderProvider = function(options) {
  // options.url = "http://media-center1.2.rawl-app.lkoc.webdev/library/upload-file";
  options.url = "https://api-mc.rawl-app.com/library/upload-file";
  return new FileUploader(options);
};

window["plugin"] = function() {
};

let FunctionItemsListPluginPackage = {};
const FunctionItemsListPlugin = Vue.extend(
  {
    props: {
      items: {},
      isSelect: {},
      actions: {},
      isInProgress: {},
    },
    data: () => (
      {
        itemsSelectList: [],
        unWatch: () => {
        }
      }
    ),
    destroyed: function() {
      this.unWatch();
    },
    created: function() {
      console.log("FunctionItemsListPluginPackage", FunctionItemsListPluginPackage);
      this.unWatch = this.$watch("$route", function(newValue, oldValue) {
        if (this.$route && this.$route.matched[0].meta.handle === "space") {
          this.$emit("refreshItemsList");
        }
      });
    },
    methods: {
      isIconUrl() {
        if (this.$props.action?.icon) {
          return !!this.$props.action.icon.match(/^(ht|f)tp(s?)/);
        }
        return false;
      },

      isAdmin() {
        return false;
      },

      onClick(e: Event, action: any, item: any, index: number) {
        action.onClick(e, item, index);
      },

      checkItem(el: any, file: any, index: number, obj: any) {
        if (this.existInList(file)) {
          this.unSelectFile(file);
        } else {
          this.itemsSelectList.push(file.id);
        }
      },
      existInList(file: any) {
        return !!this.itemsSelectList.find((id) => id === file.id);
      },
      unSelectFile(file: any) {
        this.itemsSelectList = this.itemsSelectList.filter((id) => id !== file.id);
      },
      prepareToSize(size: number) {
        return bytesToSize(size);
      }
    },
    template: `
      <table class="table table-bordered packages-table">
          <thead v-if="items">
              <tr class="" v-if="items">
                  <th v-if="isAdmin()"></th>
                  <th>#</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Size</th>
                  <th></th>
              </tr>
              <tr v-for="(item, index) in items" :key="index">
                  <td v-if="isAdmin()" align="center" class="" objectid="1">
                      <label :key="index" @click="(e) => { checkItem(e, item, index, this) }">
                          <input type="checkbox" class="checkbox">
                          <span class="checkmark"></span>
                      </label>
                  </td>
                  <td align="center" objectid="1">{{index+1}}</td>
                  <td objectid="1">{{item.name}}</td>
                  <td objectid="1">{{item.type}}</td>
                  <td objectid="1">{{prepareToSize(item.size)}}</td>
                  <td objectid="1">
                      <a class="btn btn-sm btn-default view_link" v-for="(action, buttonIndex) in actions"
                      v-if="typeof action.onlyOwner === 'undefined' || (typeof action.onlyOwner !== 'undefined' && action.onlyOwner && item.sharedInfo === null)"
                      style="font-size: 0; vertical-align: middle; margin: 0 3px;" :key="buttonIndex" @click="(e) => {action.onClick(e, item, index);}">
                          <i v-if="isIconUrl()"><img :src="action.icon" :alt="action.text"></i>
                          <i v-if="!isIconUrl()" :class="action.icon"></i>
                          <span v-if="action.text !== ''">{{action.text}}</span>
                      </a>
                  </td>
              </tr>
          </thead>
      </table>
      `
  }
);

const FunctionListeners = {
  "packageHashName": "",
  "onRenameFile": (e) => {
    console.log(e);
  },
  "startPreview": (data: any) => {
    const packageHashName = FunctionListeners.packageHashName;
    const url = `http://mcv.rawl-app.lkoc.webdev/${packageHashName}/${data.file.hash}`;
  },
  "onDeleteFile": async (file) => {
    console.log("onDeleteFile", file.hash);
    return await deleteFile(file);
  },
  "onSaveList": async (e) => {
    const name = e.name;
    const data = [...e.items].map(item => item.value);
    let url = "http://api-media-center1.2.rawl-app.lkoc.webdev/api/package/" + name + "/files";
    const result = await fetch(url,
      {
        // mode: 'cors',
        method: "PUT",
        body: JSON.stringify(data),
        headers: new Headers(
          {
            "Content-Type": "application/json",
            "Accept": "application/json",
          }
        )
      }
    );
  },
  "init": (vueObject: any) => {
  },
};

window["deleteFile"] = async function(file: any) {
};

@Component({
  components: {},
})
export default class PackagesRecord extends Vue {
  private queryParams: Array<any> = [];
  private filesData: Array<object> = [];
  private instance: any;

  constructor() {
    super();
    this.queryParams = this.$route.params;
    const queryParams = this.$route.params;
    const packageHashName = queryParams.packageHashName;
    FunctionListeners.packageHashName = packageHashName;
    window["deleteFile"] = async function(file: any) {
      console.log("hash");
      console.log(file);
      console.log(packageHashName);

      let options = {
        // mode: 'cors',
        //mode: 'no-cors',
        method: "DELETE",
        headers: new Headers(
          {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          }
        )
      };
      const data = await fetch(
        "http://api-media-center1.2.rawl-app.lkoc.webdev/api/package/" + packageHashName + "/file/" + file.hash,
        options
      ).then((data) => {
        return true;
      });
      return data;
    };
  }

  filesProvider() {
    const packageHashName = this.queryParams.packageHashName;
    const packageName = this.queryParams.packageName;
    const mediaCenter: MediaCenterAPI = this.$ioc.get("service.media_center");
    return (
      async function() {
        let files = await mediaCenter.package(packageHashName);
        Vue.prototype.$ioc.get("service.bread-cramp-update")(
          {
            fileName: "packages",
            hashName: "",
            path: "/packages"
          }, [
            {
              fileName: packageName,
              hashName: packageHashName,
              path: `/package/${packageName}/${packageHashName}`
            }
          ]
        );
        files = files.body.files.map((record) => {
          console.log(record);
          return {
            id: record["id"],
            // spaceID: record["spaceID"],
            name: record["name"],
            type: record["type"],
            size: record["size"],
            hash: record["hashName"],
            sharingInfo: record["sharingInfo"] ? record["sharingInfo"] : null,
          };
        });
        return files;
      }
    )();
  }

  destroyed(): void {
    this.instance.$destroy();
  }

  @Watch("$route", { immediate: true, deep: true })
  setWhiteBGColor() {
    this.$emit("setBGColor", "#fff");
  }

  FunctionListProvider() {
    const mediaCenter: MediaCenterAPI = this.$ioc.get("service.media_center");
    return (
      async function() {
        let files: any = await mediaCenter.packagesList();
        files = files.body.packages.map((record: any) => {
          return {
            value: record["id"],
            name: record["name"],
            hash: record["hashName"],
          };
        });
        return files;
      }
    )();
  }

  mounted(): void {
    this.$emit("setBGColor", "#fff");
    this.instance = RawlplugFilesManager(
      "#rtl-app",
      {
        filesProvider: this.filesProvider,
        listProvider: this.FunctionListProvider,
        listeners: FunctionListeners,
        fileUploader: FunctionFileUploaderProvider,
        itemsListPlugin: FunctionItemsListPlugin,
        actions: FunctionActions,
      }
    );

    console.log("this.instancethis.instancethis.instance", this.instance);
  }
}
