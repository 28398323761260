













// @ts-nocheck
import {Component, Watch, Vue} from "vue-property-decorator";
import RawlplugFilesManager from "@/resources/myLib.common.js";
import router from "../../router";
import MediaCenterAPI, {spaceUploadFile} from "@/services/MediaCenterAPI";
import bytesToSize from "@/resources/bytesToSize";


const FunctionActions = function(app: any) {
  return {
    get: function() {
      return [
        (this.deleteButton()),
        (this.previewButton()),
        (this.selectButton()),
      ];
    },

    deleteButton: function() {
      return {
        icon: "fa fa-delete",
        label: "delete",
        text: "",
        attr: {
          href: "",
          target: ""
        },
        onClick: async (e: any, file: any, index: number) => {
          const hash = file.hash;
          const fileId = app.findFile(index);
          app.pushToActionQuote({
            file,
            hash,
            action: "delete",
          });
        },
      };
    },
    previewButton: function() {
      return {
        icon: "fa fa-eye",
        label: "preview",
        text: "",
        attr: {
          href: "",
          target: ""
        },
        onClick: function(e: any, file: any, index: number, z: any) {
          const hash = file.hash;
          const fileId = app.findFile(index);
          const packageHashName = window["listeners"].packageHashName;
          const url = `http://mcv.rawl-app.lkoc.webdev/${packageHashName}/${file.hash}`;
          // @ts-ignore
          window.open(url, "_blank").focus();
          return false;
        },
      };
    },
    selectButton: function() {
      return {
        icon: "fa fa-select",
        label: "preview",
        text: "Select",
        attr: {
          href: "",
          target: ""
        },
        onClick: function(e: any, file: any, index: number, z: any) {
          console.log(app);
          app.clickFileItem(index);
        },
      };
    },
  };
};

window["plugin"] = function() {
};

const FunctionSpacesItemsListPlugin = Vue.extend(
  {
    router,
    props: {
      items: {},
      isSelect: {},
      actions: {},
      isInProgress: {},
    },
    data: () => (
      {
        spaceName: "",
        unWatch: () => {
        },
      }
    ),
    created: function() {
      this.unWatch = this.$watch("$route", function(newValue, oldValue) {
        if (this.$route && this.$route.matched[0].meta.handle === "space") {
          this.$emit("refreshItemsList");
        }
      });
    },
    destroyed: function() {
      this.unWatch();
    },
    mounted: function() {
      this.spaceName = this.$route.params.spaceName;
    },
    methods: {
      isIconUrl() {
        if (this.$props.action?.icon) {
          return !!this.$props.action.icon.match(/^(ht|f)tp(s?)/);
        }
        return false;
      },
      prepareToSize(size: number) {
        return bytesToSize(size);
      }
    },
    template: `
      <table class="table table-bordered spaces-table">
          <thead v-if="items">
              <tr class="" v-if="items">
                  <th>Name</th>
                  <th>Type</th>
                  <th>Size</th>
                  <th></th>
              </tr>
              <tr v-for="(item, index) in items" :key="index">
                  <td objectid="1">
                      <span v-if="item.type !== 'd'">{{item.name}}</span>
                      <router-link v-if="item.type === 'd'" class="btn btn-sm btn-default view_link" :to="'/spaces/' + spaceName + '/' + item.hash">{{item.name}}</router-link>
                      <span v-if="isInProgress(item.hash) !== false" class="progress-bar"></span>
                  </td>
                  <td objectid="1">{{item.type}}</td>
                  <td objectid="1">{{ item.type === 'f' ? prepareToSize(item.size ?? 0) : '' }}</td>
                  <td objectid="1">
                      <a v-if="item.type === 'f'" class="btn btn-sm btn-default view_link" v-for="(action, actionIndex) in actions" :key="actionIndex" @click="(e) => {action.onClick(e, item, index);}">
                          <span>
                              <i v-if="isIconUrl()"><img :src="action.icon" :alt="action.text"></i>
                              <i v-if="!isIconUrl()" :class="action.icon"></i>
                              <span v-if="action.text !== ''">{{action.text}}</span>
                          </span>
                      </a>
                  </td>
              </tr>
          </thead>
      </table>
    `
  }
);

window["$route"] = null;
window["$ioc"] = null;


@Component({
  components: {},
})
export default class SpacesRecord extends Vue {
  private queryParams: Array<any> = [];
  private filesData: Array<object> = [];
  private spaceName = "";
  private hashName = "";
  private instance: any;
  private actualSpace: any;

  constructor() {
    super();
    this.queryParams = this.$route.params;
  }

  getRoute() {
    return this.$route;
  }

  destroyed(): void {
    this.instance.$destroy();
  }

  @Watch("$route", { immediate: true, deep: true })
  setWhiteBGColor() {
    this.$emit("setBGColor", "#fff");
  }

  FunctionFileUploaderProvider(options: any) {
    console.log("options", options);
    return spaceUploadFile(this.spaceName, options);
  };

  FunctionListProvider() {
    const mediaCenter: MediaCenterAPI = this.$ioc.get("service.media_center");
    return (
      async function() {
        let files: any = await mediaCenter.packagesList();
        files = files.body.packages.map((record: any) => {
          return {
            value: record["id"],
            name: record["name"],
            hash: record["hashName"],
          };
        });
        return files;
      }
    )();
  }

  filesProvider() {
    window["$ioc"] = this.$ioc;
    this.spaceName = this.$route.params.spaceName;
    this.hashName = this.$route.params.hashName;
    const getRoute = this.getRoute;
    window["$route"] = getRoute;
    const mediaCenter: MediaCenterAPI = this.$ioc.get("service.media_center");
    mediaCenter.spacesListFromCache().then(
      (spaces) => {
        return spaces.body.spaces;
      }
    ).then(
      (spaces: []) => {
        console.log("spacesspaces", spaces);
        return spaces.filter((space) => space.name === this.spaceName);
      }
    ).then(
      (space) => {
        this.actualSpace = space[0];
      }
    );
    return (
      async function() {
        const $route = getRoute();
        const spaceName = $route.params.spaceName;
        const hashName = $route.params.hashName;
        if (typeof spaceName === "undefined") {
          return [];
        }
        let files: any = await mediaCenter.spaceFiles(spaceName, hashName);
        const path = files.body.pathStructure.map(
          ({ file_name, hash_name }: any) => {
            let url = `/spaces/${spaceName}`;
            if (hash_name) {
              url += `/${hash_name}`;
            }
            return {
              fileName: file_name,
              hashName: hash_name,
              path: url
            };
          }
        );

        Vue.prototype.$ioc.get("service.bread-cramp-update")(
          {
            fileName: "spaces",
            hashName: "",
            path: "/spaces"
          }, path
        );

        files = files.body.files.map((record: any) => {
          return {
            id: record["id"],
            name: record["file_name"],
            type: record["type"],
            hash: record["hash_name"],
            createAt: record["create_at"],
            size: parseInt(record["size"]),
          };
        });
        return files;
      }
    )();
  }

  FunctionListeners(aaa) {
    const mediaCenter: MediaCenterAPI = this.$ioc.get("service.media_center");
    const getRoute = this.getRoute;
    let VueObjectI;

    return {
      "packageHashName": "",
      "onRenameFile": (e: any) => {
        console.log(e);
      },
      "onDeleteFile": async (file: any) => {
        const result = await mediaCenter.deleteFileFormSpace(this.spaceName, file.hash);
        console.log("onDeleteFile - status", file.hash, result);
        return true;
      },
      "onSaveList": async (e) => {
        console.log("222222222");
        const packageRecord = e.name;
        const $route = getRoute();
        const spaceName = $route.params.spaceName;
        const data = [...e.items].map(item => item.item.hash);
        const spaces = await mediaCenter.spacesListFromCache().then(
          (result: any) => {
            return result.body.spaces;
          }
        );
        const findSpaces = spaces.filter((space) => space.name === spaceName);
        const dataStructure = {
          packageID: packageRecord.value,
          spaceID: findSpaces[0].id,
          files: data
        };
        const result = await mediaCenter.saveFilesToPackage(packageRecord.hash, JSON.stringify(dataStructure));
        console.log("Save result = ", result);
      },
      "init": (VueObject) => {
        VueObjectI = VueObject;
        console.log("VueObject", VueObject);
      },
    };
  };

  mounted(): void {
    this.$emit("setBGColor", "#fff");

    this.instance = RawlplugFilesManager(
      "#rtl-app",
      {
        filesProvider: this.filesProvider,
        listProvider: this.FunctionListProvider,
        listeners: this.FunctionListeners(),
        fileUploader: this.FunctionFileUploaderProvider,
        itemsListPlugin: FunctionSpacesItemsListPlugin,
        //listPlugin: '',
        actions: FunctionActions,
        preUploadValidate: (file: any) => {
          console.log("actualSpace", this.actualSpace);
          return file.size <= this.actualSpace.details.maxUploadFileSize;
        },
        errorDragAndDrop: (file: any) => {
          console.log("errorDragAndDrop", file);
        },
      }
    );
  }
}
