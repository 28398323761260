












// @ts-nocheck
import {Component, Vue} from "vue-property-decorator";
import SimpleSpaceRecord from "@/components/Record/SimpleSpaceRecord.vue";

@Component({
  components: {
    SimpleSpaceRecord
  },
})
export default class Spaces extends Vue {
  private spaces: Array<object> = [];

  mounted(): void {
    this.$ioc.get("service.media_center").spacesListFromCache().then(
      (result: any) => {
        this.spaces = result.body.spaces.map(
          (spaceItem: any) => Object.assign(spaceItem, { path: `/spaces/${spaceItem.name}/`, filesQty: 0, memoryTotal: 0 })
        );
      }
    );
  }
}
